.bg_auth {
	background: url('../../image/bg_auth.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	object-fit: contain;
	.bg_left {
		background: url('../../image/storage.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		object-fit: contain;
	}
	.item_auth {
		border-radius: 10px;
		.item-form-input {
			margin-bottom: 2rem;
		}
		.item-check {
			margin-bottom: 0 !important;
		}
		.login-button {
			margin: 0px 0px 15px 0px !important;
			height: 40px !important;
			width: 100% !important;
			div {
				font-size: 16px;
				text-align: center !important;
			}
		}
	}
}
.ant-message {
	&-error,
	&-success {
		display: flex;
		align-items: center;
	}
}
