.ant-form-item-required::before {
	display: none !important;
}
.ant-table-thead{
	border-top: 1px solid #000 !important;
}

.ant-table-cell {
	// border: 1px solid #000 !important;
	font-size: 16px;
	border-right: 1px solid #000 !important;
	border-bottom: 1px solid #000 !important;
}
p{
	margin: 0;
}
.ant-table-tbody{
	padding: 8px !important;
  }
  .ant-table-tbody > tr > td{
	padding: 8px !important;
  }

.active {
	color:#bae6fd;
	font-weight: 600;


}
.link{
color: #ffffff;
}