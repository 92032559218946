@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'antd/dist/antd.min.css';
* {
	margin: 0;
	padding: 0;
	box-sizing: 0;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.storage-label-printing{
	display: grid;
	grid-template-columns: repeat(5, 1fr);
    gap: 5px;
}

@media only screen and (max-width: 1024px) {
	.storage-label-printing {
		grid-template-columns: repeat(3, 1fr);
	}
}
