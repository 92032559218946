@import 'antd/dist/antd.min.css';
.app {
	text-align: center;
}

.app-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.app-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.app-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.app-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

td:empty {
	border: none;
}
p{
	margin: 0;
}
.rounded-sm {
	border-radius: 2px;
}
.rounded {
	border-radius: 4px !important;
}
.rounded-md {
	border-radius: 6px;
}
.rounded-lg {
	border-radius: 8px !important;
}
.rounded-full {
	border-radius: 100% !important;
}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table {
	border-left: 1px solid #000;
	border-top: 1px solid #000 !important;
	
}
